/* 사이드바 오픈시 동적인효과와 함께 나타남 */
.sidebar {
  width: 260px;
  height: 100%;
  position: fixed;
  top: 0;
  left: -260px;
  z-index: 1000;
  background: #333;
  color: #fff;
  transition: left 0.4s ease;
  box-shadow: 3px 3px 3px rgba(51, 51, 51, 0.5);
}

/* 사이드바 OFF 상태 */
.sidebar--open {
  left: 0;
}

.sidebar__logo {
  padding: 50px 50px;
  border-bottom: 1.5px solid #444;
  display: flex;
}

.sidebar__menus {
  position: relative;
  width: 100%;
  list-style:none;
  padding: 0;
  margin: 0;
}
