/* 사이드바 li 요소 */
.sidebar__menu-item {
  margin-bottom: 10px;
}

/* 사이드바 최상위 아이템 (depth level = 0 공통 스타일) */
.sidebar__top {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  color: white;
  background-color: transparent;
  font-size: 1.1rem;
  height: 60px;
  padding: 0 16px;
}

/* 사이드바 최상위 아이템 active 상태 */
.sidebar__top--active {
  background-color: #444;
}

/* 사이드바 최상위 메뉴의 버튼 스타일 */
.sidebar__top-button {
  cursor: pointer;
  margin: 0;
  border: none;
}

/* 사이드바 최상위 메뉴의 NavLink 스타일 */
.sidebar__top-link {
  text-decoration: none;
}

/* 사이드바 최상위 메뉴 hover 스타일 */
.sidebar__top-button:hover,
.sidebar__top-link:hover {
  background-color: #444;
}

/* 사이드바 하위 메뉴 (depth level >= 1일 경우) */
.sidebar__submenu {
  display: flex;
  align-items: center;
  width: 100%;
  color: white;
  background-color: transparent;
}

/* 사이드바 하위 메뉴의 버튼 스타일 */
.sidebar__submenu-button {
  padding: 0px 16px 0px 25px;
  height: 50px;
  cursor: pointer;
  border: none;
  font-size: 0.95rem;
  margin: 0px;
}

/* 사이드바 하위 메뉴 active 상태 */
.sidebar__submenu--active {
  background-color: #444;
}

/* 사이드바 하위 메뉴의 NavLink 스타일 */
.sidebar__submenu-link {
  display: flex;
  align-items: center;
  padding-left: 40px;
  height: 50px;
  box-sizing: border-box;
  text-decoration: none;
}

/* 사이드바 하위 메뉴 hover 상태 */
.sidebar__submenu-button:hover,
.sidebar__submenu-link:hover {
  background-color: #444;
}

/* 화살표 스타일 */
.button__arrow {
  display: inline-block;
  transition: transform 0.3s ease;
  margin-left: auto;
  vertical-align: 0.09em;
  border-left: 0.42em solid;
  border-top: 0.32em solid transparent;
  border-bottom: 0.32em solid transparent;
}

/* 아래 화살표 */
.button__arrow--down {
  transform: rotate(90deg);
}

/* 오른쪽 화살표 */
.button__arrow--right {
  transform: rotate(0deg);
}